.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header-image {
    background-image: url("./assets/bg-3.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: #999; 
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Base styles for pagination links */
.pagination-link {
  display: inline-block;
  padding: 5px 10px;
  margin: 2px;
  text-decoration: none;
  color: white;
}

.li.pagination.previous {
  color: #00d1b2 !important;
}

/* Styles for the current pagination link */
li.pagination-link.is-current {
  border: 1px solid #00d1b2 !important;
  background-color: #00d1b2 !important;
  color: #fff;
  border-color: #00d1b2 !important;
}

a.pagination-link {
  text-decoration: none !important;
}

pagination-link a.active {
  text-decoration: none !important;
  border: 1px solid #00d1b2 !important;
  border-color: #00d1b2 !important;
  color: white !important;
}

li.a.pagination-link.is-current {
  text-decoration: none;
  border: 1px solid #00d1b2 !important;
  border-color: #00d1b2 !important;
  color: white !important;
}

li.pagination-link.is-current.a {
  text-decoration: none;
  border: 1px solid #00d1b2 !important;
  border-color: #00d1b2 !important;
  color: white !important;
}

a.pagination-link.is-current {
  text-decoration: none;
  border: 1px solid #00d1b2 !important;
  background-color: #00d1b2 !important;
  color: #fff;
  border-color: #00d1b2 !important;
}

/* Tabs */
#tab-content p {
  display: none;
}

#tab-content p.is-active {
  display: block;
}
